import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "~/components/ui/accordion"

export default function Faq() {
  return (
    <section class="py-32">
      <div class="container">
        <h1 class="mb-4 text-3xl font-semibold md:mb-11 md:text-5xl">
          常见问题
        </h1>
        <Accordion multiple collapsible>
          <AccordionItem value="item-1">
            <AccordionTrigger class="hover:text-foreground/60 hover:no-underline">
              我的视频或音频保存在哪里？
            </AccordionTrigger>
            <AccordionContent>
              虽然这是一个网页应用，但是你的视频都是保存在你本地的浏览器里面的，只有在解析成文字的时候，当视频文件太大的时候，才会上传到服务器，同时我们的服务器会定期删除服务器端的视频。
            </AccordionContent>
          </AccordionItem>

          <AccordionItem value="item-2">
            <AccordionTrigger class="hover:text-foreground/60 hover:no-underline">
              支持哪些浏览器？
            </AccordionTrigger>
            <AccordionContent>已测试支持 Chrome 和 Edge。</AccordionContent>
          </AccordionItem>

          <AccordionItem value="item-3">
            <AccordionTrigger class="hover:text-foreground/60 hover:no-underline">
              视频剪辑工具支持哪些格式？
            </AccordionTrigger>
            <AccordionContent>
              已经测试支持 MP4格式的视频，其他格式还没有测试。
            </AccordionContent>
          </AccordionItem>

          <AccordionItem value="item-4">
            <AccordionTrigger class="hover:text-foreground/60 hover:no-underline">
              如何联系反馈使用体验？
            </AccordionTrigger>
            <AccordionContent>
              您可以在X（twitter）联系作者 @youyidev 。
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </section>
  )
}
